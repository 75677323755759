import { MenuItem } from "@mui/material";
import { T } from "../../../../util/t";
import { CloudUpload } from "@mui/icons-material";
import { SpreadsheetImporter } from "../../../../importer/SpreadsheetImporter";
import { getEventImportFields } from "../../../../importer/fields/eventImportFields";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATION_WITH_FEDERATION_PROPERTIES } from "../../../../../graphql/organisation";
import { useState } from "react";
import { useOrganisation } from "../../../../providers/OrganisationProvider";
import { useRowHook } from "../../../../layout/event/dashboard/ImportResultsButton";
import { useEventObject } from "../EventProvider";
import { eventDivisionsAndEntriesFromData } from "../../../../layout/event/dashboard/eventDivisionsAndEntriesFromData";
import { importAthletes } from "../../../../../actions/eventDirector";
import { useDispatch } from "react-redux";

export const ImportAthletesButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { id: organisationId } = useOrganisation();
    const event = useEventObject();
    const rowHook = useRowHook(event?.eventDivisions);
    const dispatch = useDispatch();

    const { data } = useQuery(GET_ORGANISATION_WITH_FEDERATION_PROPERTIES, { variables: { id: organisationId } }),
        organisation = data?.organisation;

    const onImport = data => {
        const { entries } = eventDivisionsAndEntriesFromData(data, organisation, false);
        dispatch(importAthletes(event.id, entries));
    };

    if (!organisation) return null;

    return (
        <>
            <MenuItem onClick={() => setIsOpen(true)}>
                <T>Import athletes</T>
                &nbsp;&nbsp;
                <CloudUpload fontSize="small"/>
            </MenuItem>

            <SpreadsheetImporter
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onSubmit={onImport}
                fields={getEventImportFields(organisation, false)}
                rowHook={rowHook}
                isNavigationEnabled
            />
        </>
    );
};
