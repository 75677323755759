import { Link, Stack, Typography } from "@mui/material";
import { T } from "./util/t";
import { useRouteMatch } from "react-router-dom";
import { useIsEmbedded } from "hooks/useIsEmbedded";

export const TheHeatSheet = () => {
    const isHome = useRouteMatch("/");
    const isDirectorPage = useRouteMatch("/director");
    const isEventPage = useRouteMatch("/events/:id");
    const isSchedulePage = useRouteMatch("/events/:id/schedule");
    const isRegistrationPage = useRouteMatch("/events/:id/registration");
    const isClubLeaderboardPage = useRouteMatch("/events/:id/club-leaderboard");
    const isDivisionPage = useRouteMatch("/events/:id/divisions/:eventDivisionId");
    const isEntriesPage = useRouteMatch("/events/:id/divisions/:eventDivisionId/entries");
    const isLeaderboardPage = useRouteMatch("/events/:id/divisions/:eventDivisionId/leaderboard");
    const isTeamLeaderboardPage = useRouteMatch("/events/:id/divisions/:eventDivisionId/team-leaderboard");
    const isResultPage = useRouteMatch("/events/:id/divisions/:eventDivisionId/result");
    const isOrganisationPage = useRouteMatch("/:shortName");
    const isOrganisationEventsPage = useRouteMatch("/:shortName/events");
    const isFederationPage = useRouteMatch("/:shortName/federated-organisations");
    const isRankingsPage = useRouteMatch("/:shortName/rankings");
    const isMembershipsPage = useRouteMatch("/:shortName/memberships");
    const isSignOnPage = useRouteMatch("/:shortName/series/:seriesId/sign-on");

    const isLogin = useRouteMatch("/login");
    const isForgotPassword = useRouteMatch("/forgot-password");
    const isRecoverPassword = useRouteMatch("/recover-password");
    const isSignUp = useRouteMatch("/sign-up");
    const isTermsAndConditions = useRouteMatch("/terms_and_conditions");
    const isPrivacyPolicy = useRouteMatch("/privacy_policy");

    const isEmbedded = useIsEmbedded();

    if (isLogin?.isExact || isForgotPassword?.isExact || isRecoverPassword?.isExact || isSignUp?.isExact || isTermsAndConditions?.isExact || isPrivacyPolicy?.isExact) return null;
    if (isDirectorPage && !isDirectorPage.isExact) return null;
    if (isEmbedded) return null;

    return (
        isHome?.isExact ||
        isDirectorPage?.isExact ||
        isEventPage?.isExact ||
        isSchedulePage?.isExact ||
        isRegistrationPage?.isExact ||
        isClubLeaderboardPage?.isExact ||
        isDivisionPage?.isExact ||
        isEntriesPage?.isExact ||
        isLeaderboardPage?.isExact ||
        isTeamLeaderboardPage?.isExact ||
        isResultPage?.isExact ||
        isOrganisationPage?.isExact ||
        isOrganisationEventsPage?.isExact ||
        isFederationPage?.isExact ||
        isRankingsPage?.isExact ||
        isMembershipsPage?.isExact ||
        isSignOnPage?.isExact
    ) && (
        <Stack bgcolor="brand.secondary" justifyContent="center" direction="row" padding={1} spacing={0.5} flexWrap="wrap" textAlign="center">
            <Typography>
                <Typography textTransform="uppercase" variant="brandLabel1">
                    <T>The 2024 heat sheet</T>
                </Typography>
                {" "}
                <Typography variant="brandBody1"><T>12 months of action sports' data in 1 place.</T></Typography>
            </Typography>
            <Typography>
                <Link variant="brandBody1" href="https://theheatsheet.liveheats.com" target="_blank" underline="none" sx={{ textDecoration: "underline", textUnderlineOffset: "3px", textDecorationThickness: "1px" }}>
                    <T>Get your personalised Heat Sheet</T>
                </Link>
                {" "}
                <Typography variant="brandBody1" display="inline-block" sx={{ transform: "scale(1.75) translate(1px,-1.5px)" }}>⇗</Typography>
            </Typography>
        </Stack>
    );
};
